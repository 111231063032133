<template>
  <div v-if="activities" class="card card-shadow">
    <div class="card-inner border-bottom">
      <div class="card-title-group">
        <div class="card-title"><h6 class="title">Recent Document Activity</h6></div>
        <div class="card-tools">
           <router-link :to="{name: 'filehistory'}" >View All</router-link>
        </div>
      </div>
    </div>
    <div v-if="!activities.length">
      <p class="p-2 text-center">No activity.</p>
    </div>
    <ul v-if="activities" class="nk-support">
      <li v-bind:key="activity.id" v-for="activity in activities" class="nk-support-item">
        <div class="">
          <avatar style="margin:2px;" :src="activity.avatarURI" :size="30" :rounded="true" v-if="activity.userName" :username="activity.userName" />
        </div>
        <div class="nk-support-content">
          <div class="title">
            <span>{{activity.userName}}</span>
            <span class="badge badge-dot badge-dot-xs badge-success ml-1">{{ activity.action}}</span>
          </div>
          <p>{{activity.fileName}}</p>
          <span class="time">{{$moment(activity.createdDate).fromNow()}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
   data () {
     return {
       activities: null,
     }
   },
   created() {
     this.getFileHistory()
   },
   methods: {
        getFileHistory () {
            this.$http.get('/widgets/Get_RecentFileHistory')
            .then((response) => {
                this.activities = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
   }
};
</script>

<style></style>
