<template>
  <div class="card  card-full">
    <div class="card-inner">
      <div class="card-title-group align-start mb-3">
        <div class="card-title">
          <h6 class="title">Absences</h6>
          <p>
            Today/ This Week
          </p>
        </div>
      </div>
      <div>
        <p class="p-2 text-center">There are no absences this week</p>
      </div>
      <!-- <div class="user-activity-group ">
        <div class="user-activity">
          <em class="icon ni ni-users"></em>
          <div class="info">
            <span class="amount">John Smith</span
            ><span class="title">11:00 - 12:00pm</span>
          </div>
        </div>
        <hr>
        <div class="user-activity">
          <em class="icon ni ni-users"></em>
          <div class="info">
            <span class="amount">Sally Smith</span
            ><span class="title">12:00 - 1:00pm</span>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
