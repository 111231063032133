<template>
    <div class="card card-shadow">
        <div class="card-inner">
            <div class="card-title">
                <h6 class="nk-block-title title">Files Shared With Me</h6>
            </div>
            <div class="card-body">
                <div class="nk-files nk-files-view-grid" v-if="filesandfolders.length">
                    <div class="nk-files-list">
                        <div style="position:relative;" class="nk-file-item nk-file" v-for="f in filesandfolders" :key="f.id">
                            <div class="nk-file-info">
                                <a @click="pushToShared()" class="nk-file-link">
                                    <div class="nk-file-title">
                                        <div class="nk-file-icon">
                                            <span class="nk-file-icon-type">
                                                    <img class="img-time-matters" :src="require(`@/assets/images/icons/${f.imageName}`)">
                                            </span>
                                        </div>
                                        <div class="nk-file-name">
                                            <div class="nk-file-name-text">
                                                <span class="title">{{ f.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="col-xxl">
                        <div class="preview-icon-box card">
                            <span class="nk-file-icon-type" style="width:250px; height:250px; opacity:50%; margin-top:50px">
                                <img class="img-time-matters" :src="require(`@/assets/images/icons/support-general.svg`)">
                            </span>
                            <span class="preview-icon-name" style="margin-top:20px; fontSize:15px;">No Files Available</span>
                        </div><!-- .preview-icon-box -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filesandfolders: []
        }
    },
    created() {
        this.getSharedWithMeFolder()
    },
    methods: {
        getSharedWithMeFolder() {
            this.$http.get('/docs/Get_FilesAndFoldersSharedWithMeWidget/')
            .then((response) => {
                //this.intMyFolderId = id
                //this.intMyRoot = false
                this.filesandfolders = response.data
                //this.getFolderBreadcrumbs()
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        pushToShared(){
            this.$router.push( { name: 'docs', params: { sharedFoldersChanged: true } })
        }
    }
}
</script>

<style>

</style>