<template>
  <div class="card card-shadow">
        <div class="row card-inner">
          <div class="col-md-8">
          <managerholidayrequests />
        </div>
        <div class="col-md-4 border-left">
          <managerpeopleonholiday />
        </div>
        </div>
  </div>
</template>

<script>
import managerholidayrequests from "@/components/widgets/people/managerholidayrequests"
import managerpeopleonholiday from "@/components/widgets/people/managerpeopleonholiday"

export default{
  components: { managerholidayrequests,managerpeopleonholiday, }

}
</script>

<style>

</style>