<template>
  <div v-if="widgetData" class="card  card-full">
    <div class="card-inner border-bottom">
      <div class="card-title-group">
        <div class="card-title"><h6 class="title">Holiday Requests</h6></div>
        <div class="card-tools">
           <router-link :to="{name: 'holidayrequests'}" class="btn btn-dark btn-sm">View All</router-link>
        </div>
      </div>
    </div>
    <div v-if="!widgetData.holidayRequests || !widgetData.holidayRequests.length ">
        <p class="p-2 text-center">There are no outstanding or recent holiday requests</p>
    </div>
    <ul v-if="widgetData.holidayRequests && widgetData.holidayRequests.length" class="nk-activity">
      <li v-for="request in widgetData.holidayRequests" v-bind:key="request.id" class="nk-activity-item">
        <div class="nk-activity-media">
           <avatar  :src="request.avatarURI" style="margin:2px;" :size="30" :rounded="true" v-if="request.userName" :username="request.userName" />
        </div>
        <div class="nk-activity-data w-100">
          <span class="badge badge-dot badge-dot-xs badge-success float-right">{{request.requestStatus}}</span>
          <div class="label">{{request.userName}} - {{request.absenceType}} </div>
          <span class="time">{{$moment(request.startDate).format("LL")}} to {{$moment(request.endDate).format("LL")}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
   data () {
     return {
       widgetData: null,
     }
   },
   created() {
     this.getWidget()
   },
   methods: {
        getWidget () {
          this.$http.get('/widgets/Get_Recent_HolidayRequests/')
            .then((response) => {
                this.widgetData = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
   }
};
</script>

<style></style>
