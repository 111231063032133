<template>
  <div v-if="userNotification" class="card card-shadow">
    <div class="card-inner border-bottom">
      <div class="card-title-group">
        <div class="card-title"><h6 class="title">Recent Notifications</h6></div>
        <div class="card-tools">
           <router-link :to="{name: 'mynotifications'}" >View All</router-link>
        </div>
      </div>
    </div>
    <div v-if="!userNotification.userNotifications.length">
      <p class="p-2 text-center">No notifications.</p>
    </div>
    <div v-if="userNotification.userNotifications">
      <div  class="card-inner p-2" v-bind:key="notification.id" v-for="notification in userNotification.userNotifications">
        <div class="nk-wg-action">
           <div class="nk-wg-action-content" style="padding-left:0px;">
            <div class="title">
              <em v-if="notification.entityTypeId  === 1" class="icon ni ni-ticket" style="position:relative; font-size:15px;"></em>
              <em v-if="notification.entityTypeId  === 2" class="icon ni ni-ticket" style="position:relative; font-size:15px;"></em>
              <em v-if="notification.entityTypeId  === 3" class="icon ni ni-ticket" style="position:relative; font-size:15px;"></em>
              <em v-if="notification.entityTypeId  === 4" class="icon ni ni-ticket" style="position:relative; font-size:15px;"></em>
              <em v-if="notification.entityTypeId  === 5" class="icon ni ni-ticket" style="position:relative; font-size:15px;"></em>
              {{notification.title}}
            </div>
            <p>
              {{notification.details}}
            </p>
          </div>
          <a href="#" class="btn btn-icon btn-trigger mr-n2"><em class="icon ni ni-forward-ios"></em></a>
        </div>
      </div>
    </div>

    </div>
</template>

<script>
export default {
   data () {
     return {
       userNotification: null,
     }
   },
   created() {
     this.getWidget()
   },
   methods: {
        getWidget () {
          this.$http.get('/widgets/Get_Recent_User_Notifications/' + null)
            .then((response) => {
                this.userNotification = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
   }
};
</script>

<style></style>
