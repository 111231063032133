<template>
  <div v-if="widgetData" class="card card-shadow">
    <div class="card-inner border-bottom">
      <div class="card-title-group">
        <div class="card-title"><h6 class="title">Recent Messages</h6></div>
        <div class="card-tools">
           <router-link :to="{name: 'mymessages'}" >View All</router-link>
        </div>
      </div>
    </div>
    <div v-if="!widgetData.messages.length">
      <p class="p-2 text-center">No messages.</p>
    </div>
    <ul v-if="widgetData.messages" class="nk-support">
      <li v-bind:key="message.id" v-for="message in widgetData.messages" class="nk-support-item">
        <div class="">
          <avatar  :src="message.userAvatarUri" style="margin:2px;" :size="30" :rounded="true" v-if="message.userName" :username="message.userName" />
        </div>
        <div class="nk-support-content">
          <div class="title">
            <span>{{message.userName}}</span
            ><span class="badge badge-dot badge-dot-xs badge-success ml-1"
              >Viewed</span
            >
          </div>
          <p>{{message.content}}</p>
          <span class="time">{{$moment(message.createdDate).fromNow()}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
   data () {
     return {
       widgetData: null,
     }
   },
   created() {
     this.getWidget()
   },
   methods: {
        getWidget () {
          this.$http.get('/widgets/Get_Recent_User_Messages/' + null)
            .then((response) => {
                this.widgetData = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
   }
};
</script>

<style></style>
