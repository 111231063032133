<template>
  <div v-if="stats" class="">
    <div class="col-md-12 card card-shadow">
      <div class="row ">
        <div class="col-md-8 ">
          <ticketsopenedclosedbydatestatschart @monthBeforeLastMonth="setMonthBeforeLast()" @lastMonth="setLastMonth()" @thisMonth="setThisMonth()"/>
        </div>
        <div class="col-md-4">
          <div class="row rounded m-3" style="border: 2px solid #6576FF; border-width:2px !important;">
            <div class="col-4">
                <a-progress :strokeColor="'#6576FF'" type="circle"
                :width="50" 
                :percent="parseFloat((stats.outstandingCount / stats.totalCount) * 100)"
                :format="() => stats.outstandingCount"/>
            </div>
            <div class="col-8">
              <h4 style="color: #6576FF; margin-top:10px;">Outstanding</h4>
            </div>
          </div>  
          <div class="row border rounded border-success m-3" style="border-width:2px !important;">
            <div class="col-4">
                <a-progress :strokeColor="'#1EE0AC'" type="circle"
                :width="50" 
                :percent="parseFloat((stats.completedCount / stats.totalCount) * 100)"
                :format="() => stats.completedCount"/>
            </div>
            <div class="col-8">
              <h4  class="text-success" style="margin-top:10px;">Completed</h4>
            </div>
          </div>  
          <div class="row border rounded border-danger m-3" style="border-width:2px !important;">
            <div class="col-4">
                <a-progress
                :strokeColor="'#E85347'" type="circle" 
                :width="50" 
                :percent="parseFloat((stats.overdueCount / stats.totalCount) * 100)"
                :format="() => stats.overdueCount"/>
            </div>
            <div class="col-8">
              <h4 class="text-danger" style="margin-top:10px;">Overdue</h4> 
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ticketsopenedclosedbydatestatschart from "./ticketsopenedclosedbydatestatschart.vue";
export default {
  data (){
    return {
      stats: null,
      widgetFetcher: {
          start: this.$moment().startOf('month')
      }
    }
  },
  components: { ticketsopenedclosedbydatestatschart },
  created() {
    this.getTicketStats();
  },
  methods: {
    getTicketStats() {
      this.$http.post("/widgets/Get_ticketStatsBar/", this.widgetFetcher)
        .then((response) => {
          this.stats = response.data;
        });
    },
    setMonthBeforeLast() {
      this.widgetFetcher.start = this.$moment().startOf('month'),
      this.widgetFetcher.end = this.$moment().endOf('month')
      this.getTicketStats()
    },
    setLastMonth() {
      this.widgetFetcher.start = this.$moment().subtract(1, 'months').startOf('month'),
      this.widgetFetcher.end = this.$moment().subtract(1, 'months').endOf('month')
      this.getTicketStats()
    },
    setThisMonth() {
      this.widgetFetcher.start = this.$moment().subtract(2, 'months').startOf('month'),
      this.widgetFetcher.end = this.$moment().subtract(2, 'months').endOf('month')
      this.getTicketStats()
    }
  },
};
</script>

<style></style>
